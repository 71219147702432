exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-aboutus-page-aboutus-js": () => import("./../../../src/pages/AboutusPage/Aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-page-aboutus-js" */),
  "component---src-pages-first-page-first-page-js": () => import("./../../../src/pages/FirstPage/FirstPage.js" /* webpackChunkName: "component---src-pages-first-page-first-page-js" */),
  "component---src-pages-home-page-home-page-js": () => import("./../../../src/pages/Home Page/HomePage.js" /* webpackChunkName: "component---src-pages-home-page-home-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-second-page-second-page-js": () => import("./../../../src/pages/SecondPage/SecondPage.js" /* webpackChunkName: "component---src-pages-second-page-second-page-js" */),
  "component---src-pages-thrid-page-third-page-js": () => import("./../../../src/pages/ThridPage/ThirdPage.js" /* webpackChunkName: "component---src-pages-thrid-page-third-page-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/generic-page.js" /* webpackChunkName: "component---src-templates-generic-page-js" */)
}

